//ghi đè css zalo ui cho giống vs spec\

// .zaui-input-wrapper {
//   background-color: #f2f2f2;
// }

// .zaui-input {
//   background-color: #f2f2f2;
// }

.zaui-modal-wrapper {
  z-index: 2023 !important;
}

.zaui-modal-content-main {
  padding: 0;
}

.followOANotification{
  position: unset !important;
}

.customBottomAction{
  .zaui-bottom-navigation-item-icon{
    justify-content: center;
    align-items: center;
  }
}

.orderTabs{
  .zaui-tabs-tabbar{
    position: fixed;
    overflow: auto;
    z-index: 1;
   
  }

  .zaui-tabs-tabbar-item{
    min-width: unset;
    max-width: fit-content;
    overflow: unset;
    text-overflow: unset;
  }

  .zaui-tabs-tabbar-active-line{
    background-color: var(--primary);
  }

  
}
@media screen and (min-width: 430px) {
  .modalImageViewer{
    .zaui-modal-content{
      width: 430px !important;
      height: 930px !important;
      max-width: unset;
      max-height: unset;
      background-color: transparent;
    }
  }

.categoryTabs{
  .zaui-tabs-tabbar{
    position: fixed;
    overflow-y: auto;
    z-index: 1;
    width: 430px;
   
  }

  .zaui-tabs-tabbar-item{
    min-width: unset;
    max-width: fit-content;
    overflow: unset;
    text-overflow: unset;
  }

  .zaui-tabs-tabbar-active-line{
    background-color: var(--primary);
  }
}
.modalImageViewer{
  .zaui-mask{
    width: 430px !important;
    left: calc(50vw - 215px) !important;;
  }
  
  .zaui-sheet-wrapper{
    width: 430px !important;
  }
  
  .zaui-modal-wrapper{
    left: calc(50vw - 215px) !important;;
    width: 430px;
  }

  .zaui-modal-content{
    width: 430px !important;
    height: 100vh !important;
    max-width: unset;
    max-height: unset;
    background-color: transparent;
  }

  .zaui-image-container{
    width: 430px !important;
  }

  .zaui-image-viewer-images{
    width: 430px !important;
  }

  .zaui-image-viewer-nav-btn-next{
    left: 392px ;
  }
}
}



.modalImageViewer{
  .zaui-modal-content{
    width: 100vw !important;
    height: 100vh !important;
    max-width: unset;
    max-height: unset;
    background-color: transparent;
  }
}

.address-tabs{
  // .zaui-tabs-tabbar{
  //   position: sticky;
  //   overflow: auto;
   
  // }

  .zaui-tabs-tabbar-item{
    min-width: unset;
    max-width: fit-content;
    overflow: unset;
    text-overflow: unset;
  }

  // .zaui-tabs-tabbar-item-active{
  //   border-bottom: "2px solid blue";
  // }

  .zaui-tabs-tabbar-active-line{
    background-color: var(--primary);
    // background-color: none;
  }
}